import { AUTH_WORKSPACE } from '../../shared/data/constants';
import { AuthState, setAccessToken, setRefreshToken, setWorkspaceName } from './auth-context';
import { setLocalStorageData } from '../../shared/utils/localStorage';
import { OrganizationMembershipRole } from '../../__generated__/globalTypes';
import { UserProfileData } from '../../pages/users/details/profile/__generated__/UserProfileData';

type ErrorPayload = {
  error: string,
};

export type WorkspaceInfo = {
  id: number,
  name: string,
  role: OrganizationMembershipRole | null,
  photo?: string,
  lpr?: boolean,
  autoTheft?: boolean,
};

type ProfilePayload = {
  user: UserProfileData,
  workspaces: WorkspaceInfo[],
  workspace?: WorkspaceInfo,
  workspaceId?: number,
};

export type AuthAction = {
  type: 'LOGIN',
  payload: AuthState,
} | {
  type: 'LOGOUT' | 'LOGIN_UNAUTHORIZED',
} | {
  type: 'LOGIN_ERROR',
  payload: ErrorPayload,
} | {
  type: 'UPDATE_PROFILE',
  payload: ProfilePayload,
} | {
  type: 'UPDATE_WORKSPACE',
  payload: number,
} | {
  type: 'UPDATE_PROFILE_USER',
  payload: UserProfileData,
};

const authReducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case 'LOGIN':
      setAccessToken(action.payload.accessToken);
      setRefreshToken(action.payload.refreshToken);
      setWorkspaceName(action.payload.user?.primaryOrganization.name);
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        isUnauthorized: false,
        error: '',
        workspaces: action.payload.workspaces || [],
        adminWorkspaces: (action.payload.workspaces || [])
          .filter(workspace => workspace.role === OrganizationMembershipRole.ADMIN)
          .map(workspace => {
            return {
              id: workspace.id,
              name: workspace.name
            };
          })
      };
    case 'LOGOUT':
      setAccessToken('');
      setRefreshToken('');
      setWorkspaceName(undefined);
      return {
        ...state,
        user: undefined,
        accessToken: '',
        refreshToken: '',
        isUnauthorized: false,
        error: '',
        workspaces: [],
        adminWorkspaces: [],
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        isUnauthorized: false,
        error: action.payload.error,
      };
    case 'LOGIN_UNAUTHORIZED':
      return {
        ...state,
        isUnauthorized: true,
        error: '',
      };
    case 'UPDATE_PROFILE':
      setLocalStorageData(AUTH_WORKSPACE, action.payload.workspaceId);
      setWorkspaceName(action.payload.user?.primaryOrganization.name);
      return {
        ...state,
        user: action.payload.user,
        workspaceId: action.payload.workspaceId,
        workspace: action.payload.workspace,
        workspaces: action.payload.workspaces,
        adminWorkspaces: action.payload.workspaces
          .filter(workspace => workspace.role === OrganizationMembershipRole.ADMIN)
          .map(workspace => {
            return {
              id: workspace.id,
              name: workspace.name
            };
          })
      };
    case 'UPDATE_PROFILE_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'UPDATE_WORKSPACE':
      setLocalStorageData(AUTH_WORKSPACE, action.payload);
      window.location.reload();
      return state;
    default:
      return state;
  }
};

export default authReducer;
